:not(svg) {
  all: unset; /* Use initial spec values. */
  display: revert; /* Revert to browser's default. */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  background-color: black;
  color: white;
  font: 24px/1.5 system-ui, sans-serif;
}

body {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

img {
  display: block;
  inset: 0;
  margin: auto;
  max-width: 100%;
  object-fit: contain;
  pointer-events: none;
  position: absolute;
}
